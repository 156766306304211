/** @module LogoSplash
 *  @since 2023.02.20, 23:31
 *  @changed 2023.03.02, 22:43
 */

import { Box } from '@mui/material';

// NOTE: This svg (with `purple` color can be re-painted on-the-fly (with a `fill` property).
// import LogoSvg from './assets/360-caster-logo-with-key-purple-color.svg';

import styles from './LogoSplash.module.scss';

export function LogoSplash(): JSX.Element {
  return <Box className={styles.root} />;
}
