/** @module config.api
 *  @since 2023.02.20, 23:15
 *  @changed 2023.02.20, 23:15
 */

import * as buildConfig from './build';

const useDebugApi = true;

export const apiHost =
  useDebugApi && buildConfig.isDev
    ? 'http://localhost:5000' // Debug api
    : 'https://axiomica-project.lilliputten.ru'; // TODO: Change to real address?

export const apiRoot = '/api/v1.0';
export const apiUrlPrefix = apiHost + apiRoot;

export const requestTimeout = buildConfig.isDev
  ? 10000 // DEBUG
  : 30000;

// Authorization...
export const apiUser = 'api';
export const apiPass = 'pusplndvqaivbynv';
const authStr = apiUser + ':' + apiPass;
// Authorization: Basic YXBpOnB1c3BsbmR2cWFpdmJ5bnY=
export const apiAuth = 'Basic ' + Buffer.from(authStr).toString('base64');

// Request

export const defaultDataRequestHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Authorization: apiAuth,
};
