import { createContext, useContext } from 'react';
import { injectStores } from '@mobx-devtools/tools';

import * as buildConfig from '@/config/build';
import { rootStore } from './RootStore';

// DEBUG: Ibject stores for mobx-devtool
if (buildConfig.isDevBrowser) {
  injectStores({
    rootStore,
  });
}

// TODO: Use AsyncTrunk

// Context...
export const StoreContext = createContext(rootStore);
export const StoreProvider = StoreContext.Provider;

// Use root store
export const useStore = () => useContext(StoreContext);

// Export specific store selectors (`useAppStore`, ...)?
export const useAppStore = () => useContext(StoreContext).app;
