/** @module GenericPageLayout
 *  @desc Renders parameters for `head` page part (title, meta-data, etc).
 *  @since 2023.02.21, 00:56
 *  @changed 2023.02.21, 00:56
 */

import * as React from 'react';
import classnames from 'classnames';
import { Typography } from '@mui/material';

import { WithToastsWrapper } from '@/ui';
import { HtmlHeader, THtmlHeaderProps } from '../HtmlHeader';
import { PageContent } from '../PageContent';

import styles from './GenericPageLayout.module.scss';

export interface TGenericPageLayoutProps extends THtmlHeaderProps {
  className?: string;
  children?: React.ReactNode;
}

export function GenericPageLayout(props: TGenericPageLayoutProps): JSX.Element {
  // NOTE: Get props from nextjs (as `pageProps`)
  const { className, children, ...restProps } = props;
  // <div className={classnames(className, styles.container)}>
  // prettier-ignore
  return (
    <>
      <HtmlHeader {...restProps} />
      {/* Theme root for app-through typograpy support */}
      <Typography className={classnames(className, styles.container)} component="article">
        {/* Toasts support */}
        <WithToastsWrapper>
          {/* Other app layout elements should came here */}
          <PageContent className={styles.content} stretched>
            {children}
          </PageContent>
        </WithToastsWrapper>
      </Typography>
    </>
  );
}
