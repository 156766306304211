/** @module StartPage
 *  @since 2023.02.20, 23:31
 *  @changed 2023.03.02, 21:51
 */

import { Stack } from '@mui/material';

import { AppStateWrapper, GenericPageLayout } from '@/layout';
import { subPageTitle } from '@/helpers';
import { LogoSplash } from '@/containers';

function StartPageComponent(): JSX.Element {
  const pageTitle = '360caster';
  const title = subPageTitle(pageTitle);
  return (
    <GenericPageLayout title={title}>
      <AppStateWrapper>
        <Stack alignItems="center">
          <LogoSplash />
        </Stack>
      </AppStateWrapper>
    </GenericPageLayout>
  );
}

export const StartPage = StartPageComponent;

/* // NOTE: Demo: Applying multiple wrappers...
 * export const StartPage = compose(
 *   observer,
 *   // // NOTE: Don't use wrappers on the very top app level (outside basic layout): It can be rendered around essential wrappers like toast and theme providers
 *   // withAppStateWrapperFabric({ showContentBehindLoader: true }),
 * )(StartPageComponent);
 */
