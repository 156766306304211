import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { Box, Button, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import { useAppStore } from '@/store';
import { errorToString } from '@/helpers';
import { LoaderSplash } from '@/ui';

import styles from './AppStateWrapper.module.scss';

export interface TAppStateWrapperParams {
  wrapperClassName?: string;
  contentClassName?: string;
  errorClassName?: string;
  showErrorInWrapper?: boolean;
  showContentBehindLoader?: boolean;
}

export interface TAppStateWrapperProps extends JSX.IntrinsicAttributes, TAppStateWrapperParams {
  className?: string;
  children: React.ReactNode;
}

function AppStateWrapperCore(props: TAppStateWrapperProps) {
  const {
    className,
    wrapperClassName,
    contentClassName,
    errorClassName,
    showErrorInWrapper = true,
    showContentBehindLoader,
    children,
  } = props;
  const appStore = useAppStore();
  const { inited, waiting, error } = appStore;
  const showContent = showContentBehindLoader || (inited && !waiting && !error);
  // Effect: Show error toast
  React.useEffect(() => {
    // TODO: Use option `showToastError`?
    error && toast.error(errorToString(error));
  }, [error]);
  const router = useRouter();
  const { asPath } = router;
  const isRoot = asPath === '/';
  const goToStartPage = React.useCallback(() => router.push('/'), [router]);
  return (
    <div className={classnames(className, wrapperClassName, styles.container)}>
      <div className={classnames(contentClassName, styles.contentContainer)}>
        {/* Show error */}
        {showErrorInWrapper && error && (
          <Box className={classnames(styles.contentErrorContainer)} m={2}>
            <Typography className={classnames(errorClassName, styles.contentError)} m={2}>
              {errorToString(error)}
            </Typography>
            <Stack
              className={styles.actions}
              spacing={2}
              direction="row"
              m={2}
              justifyContent="center"
            >
              {!isRoot && (
                <Button className="FixMuiButton" onClick={goToStartPage} variant="contained">
                  <span className="Text">Go to main page</span>
                </Button>
              )}
            </Stack>
          </Box>
        )}
        {showContent && children}
        {/* Show large covering loader splash if no data loaded */}
        <LoaderSplash
          className={styles.loaderSplash}
          show={waiting}
          spinnerSize="large"
          bg="white"
          mode="cover"
          fullSize
        />
      </div>
    </div>
  );
}

export const AppStateWrapper = observer(AppStateWrapperCore);
