import { action, makeObservable, observable } from 'mobx';

import { Deferred, TDeferred } from '@/helpers';

export class AppStore {
  private initDefer?: TDeferred;

  @observable waiting = false;
  @observable inited = true;
  @observable error?: Error = undefined;

  constructor() {
    makeObservable(this);
  }

  getInitDefer() {
    if (!this.initDefer) {
      this.initDefer = Deferred();
      this.initDefer.promise.catch((e) => e); // Suppress uncaught promise errors
      if (!this.waiting) {
        // NOTE: Check init/error state and resolve the promise immediately if this state has defined.
        // The case: initDefer was requested after the state has initialized.
        if (this.inited) {
          // Successfully initialized!
          this.initDefer.resolve();
        } else if (this.error) {
          // Error!
          this.initDefer.reject(this.error);
        }
      }
    }
    return this.initDefer;
  }

  getInitPromise() {
    return this.getInitDefer().promise;
  }

  @action
  initFinished() {
    // console.log('[AppStore]:initFinished');
    // TODO: Check if hasn't resolved?
    if (this.waiting) {
      this.inited = true;
      this.error = undefined;
      this.waiting = false;
      this.initDefer?.resolve();
    }
  }

  @action
  initRejected(error: Error) {
    // console.error('@:AppStore:initRejected', error);
    // TODO: Check if hasn't resolved?
    if (this.waiting) {
      this.error = error;
      this.inited = false;
      this.waiting = false;
      this.initDefer?.reject(error);
    }
  }
}

// TODO: To use singleton to use directly from any place of the app?
export const appStore = new AppStore();
