/** @module app-wrapper
 *  @since 2023.02.20, 23:41
 *  @changed 2023.02.20, 23:41
 */

import React from 'react';
import { muiTheme, ThemeProvider } from '@/core/global/mui-theme';
import { rootStore, StoreProvider } from '@/store';

// TODO: Import control nodes/hooks from `features` folder.

interface AppWrapperProps {
  children?: React.ReactNode;
}

export function AppWrapper(props: AppWrapperProps): JSX.Element {
  const { children } = props;
  return (
    <>
      <StoreProvider value={rootStore}>
        <ThemeProvider theme={muiTheme}>
          {/* TODO: Expose root control nodes or use custom hooks? */}
          {children}
        </ThemeProvider>
      </StoreProvider>
    </>
  );
}
